/* You can add global styles to this file, and also import other style files */

/* We only need to include grid system from bootstrap after redesign */
// @import "~bootstrap/dist/css/bootstrap.css";
@use "./devextreme-variables";
@use "./colors";
$grid-gutter-width: 1rem;

@import "../node_modules/bootstrap/scss/bootstrap";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Inter Regular", sans-serif;
}

b {
  font-family: "Inter Bold";
}

.card-header.card-main-header {
  background-color: #505690 !important;
}

.card-header.card-main-header h2 {
  color: white;
  font-size: 1.5em;
  margin: 5px 0;
  text-align: center;
  font-weight: 400;
}

.card-header.card-sub-header h2 {
  font-size: 1.5em;
  text-align: center;
  margin: 3px 0;
  font-weight: 300;
}

.button-sm {
  font-size: 0.9em;
  line-height: 32px;
}

.training-image {
  height: 180px;
  width: 320px;
  object-fit: cover;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.dx-drawer-panel-content {
  width: 260px !important;
}

.dx-button {
  border-radius: 4px;
  letter-spacing: normal !important;

  .dx-button-content {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
  }
}

.dx-button-has-text {
  .dx-icon {
    font-size: 14px;
    margin-inline-end: 2px;
  }
}

.dx-dropdownbutton {
  .dx-button-content {
    padding-inline-end: 10px !important;
  }

  .dx-button-mode-contained.dx-buttongroup-first-item.dx-button {
    border-start-start-radius: 4px;
    border-end-start-radius: 4px;
  }

  .dx-button-mode-contained.dx-buttongroup-last-item.dx-button {
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
  }

  .dx-dropdownbutton-toggle > .dx-button-content {
    padding-inline-start: 5px !important;
    padding-inline-end: 5px !important;
  }

  .dx-icon.dx-icon-spindown {
    &::before {
      font-size: 14px;
      content: "\f016";
    }
  }
}

.dx-field-item-label-content {
  font-size: 12px;
}

.dx-texteditor {
  background: colors.$primary-100 !important;

  &.dx-state-hover {
    .dx-texteditor-label {
      .dx-label-before,
      .dx-label,
      .dx-label-after {
        border-color: colors.$primary-300 !important;
      }
    }
  }
  &.dx-state-focused {
    .dx-texteditor-label {
      .dx-label-before,
      .dx-label,
      .dx-label-after {
        border-color: colors.$primary-500 !important;
      }

      .dx-label {
        color: colors.$primary-500;
  
        > span::after {
          color: colors.$primary-500;
        }
      }
    }
  }

  .dx-texteditor-label {
    .dx-label-before,
    .dx-label,
    .dx-label-after {
      border-color: transparent;
    }

    .dx-label {
      color: colors.$primary-400;

      > span::after {
        color: colors.$primary-400;
      }
    }
  }
}

.dx-texteditor-input {
  color: colors.$primary-700 !important;
  padding: 13px 11px 13px;
}

.dx-textarea {
  .dx-texteditor-input {
    padding: 5px 11px 13px !important;
  }
}

.dx-texteditor.dx-state-readonly {
  border: none !important;
  background: devextreme-variables.$button-default-bg !important;

  .dx-texteditor-label {
    .dx-label-before,
    .dx-label-after {
      border: none !important;
    }
  }
}

.dx-texteditor-label-outside .dx-label span {
  font-size: 12px;
  color: devextreme-variables.$form-field-item-color !important;
}

.dx-texteditor-input-container {
  .dx-placeholder::before {
    color: colors.$primary-400;
  }
}

.dx-checkbox {
  &.dx-checkbox-checked {
    .dx-checkbox-icon {
      color: colors.$white;
      background: #84ad6d;
      border: none;
    }
  }

  .dx-checkbox-icon {
    border-radius: 3px;
  }
}

.dx-layout-manager .dx-field-item:not(.dx-first-row) {
  padding-top: 11px;
}

.dx-map-container {
  border-radius: 8px;
}

.dx-tabs {
  height: 40px;

  .dx-tabs-wrapper {
    border-radius: 8px;
    padding: 2px;
    background: devextreme-variables.$button-default-bg;
  }

  .dx-tabs-wrapper,
  .dx-tabs-scrollable,
  .dx-tab {
    border-radius: 8px;
    border: none !important;
    background-color: devextreme-variables.$button-default-bg;

    &::after {
      border: none !important;
    }

    .dx-tab-selected {
      background-color: devextreme-variables.$button-normal-bg !important;

      .dx-tab-text {
        color: colors.$white !important;
      }
    }
  }
}

.dx-treelist {
  box-shadow: 4px 4px 9.7px 0px rgba(88, 94, 152, 0.04);

  .dx-treelist-headers {
    border: none !important;
    background-color: transparent;

    .dx-treelist-table {
      background-color: colors.$white;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .dx-header-row {
      height: 48px;

      td {
        vertical-align: middle;
        padding: 0 15px;

        .dx-treelist-text-content {
          color: colors.$primary-500;
          font-size: 12px;
          font-weight: 500;
          font-family: Roboto;
          line-height: 18px;
        }
      }
    }
  }

  .dx-row {
    height: 48px;
  }

  .dx-row-lines {
    td {
      vertical-align: middle !important;
      color: colors.$black;
      font-size: 12px;
      font-weight: 400;
      font-family: Roboto;
      height: 48px;
      padding: 0 15px;
      border-bottom: none;
      border-top: 1px solid colors.$gray-400;
    }
  }

  .dx-treelist-rowsview {
    background-color: transparent !important;

    .dx-treelist-table {
      background-color: colors.$white;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .dx-treelist-cell-expandable {
    .dx-treelist-icon-container {
      height: 14px;
      margin-right: 10px;
    }

    .dx-treelist-collapsed {
      span::before {
        content: "\f010";
      }
    }

    .dx-treelist-expanded {
      span::before {
        content: "\f016";
      }
    }

    .dx-treelist-expanded,
    .dx-treelist-collapsed {
      span::before {
        font-size: 14px;
        color: colors.$primary-500;
        margin-top: -10px;
      }
    }
  }
}

.dx-datagrid {
  .dx-datagrid-headers {
    border: none;

    .dx-header-row {
      height: 48px;

      td {
        vertical-align: middle;

        .dx-datagrid-text-content {
          color: colors.$primary-500;
          font-size: 12px;
          font-weight: 500;
          font-family: Roboto;
          line-height: 18px;
        }
      }
    }
  }

  .dx-row {
    height: 48px;
  }

  .dx-row-lines {
    td {
      color: colors.$black;
      font-size: 12px;
      font-weight: 400;
      font-family: Roboto;
    }
  }

  .dx-header-filter {
    &::before {
      font-family: "DXIcons Material";
    }
  }

  .dx-datagrid-header-panel {
    border: none;
    height: 87px;
  }

  .dx-toolbar-items-container {
    .dx-toolbar-after {
      max-width: 685px;
      padding: 0;

      .dx-texteditor {
        width: 400px !important;
      }
    }

    .dx-texteditor {
      .dx-texteditor-buttons-container {
        margin-right: -8px;

        .dx-button {
          opacity: 1;

          .dx-icon {
            color: colors.$primary-700;
          }
        }
      }
    }

    .dx-selectbox.dx-texteditor {
      .dx-texteditor-buttons-container {
        margin-right: 0px;
      }
    }
  }

  .dx-datagrid-pager {
    border: none;

    .dx-info {
      font-size: 12px;
    }

    .dx-page,
    .dx-page-size {
      &.dx-selection {
        color: colors.$white;
        background-color: colors.$primary-500;
      }
    }
  }
}

.dx-dropdownbox,
.dx-selectbox {
  .dx-icon-clear {
    color: colors.$primary-400;
  }
}

.dx-dropdowneditor {
  background-color: colors.$primary-100 !important;
  border: none;

  &.dx-editor-outlined {
    border: 1px solid colors.$transparent;

    &.dx-state-hover {
      border: 1px solid colors.$primary-300;
    }
    &.dx-state-focused {
      border: 1px solid colors.$primary-500;
    }
  }

  .dx-texteditor-input {
    color: colors.$primary-700 !important;
  }

  .dx-dropdowneditor-icon {
    background-color: colors.$primary-100 !important;
  }

  .dx-dropdowneditor-icon {
    &::before {
      color: colors.$primary-400;
      content: "\f016";
      font-size: 14px;
    }
  }
}

.dx-editor-outlined.dx-state-disabled {
  .dx-label-before,
  .dx-label-after,
  .dx-label {
    border: none !important;
  }
}

.dx-fileuploader-files-container {
  display: none;
}

.dx-field-item-required-mark {
  color: colors.$primary-700 !important;
}

.dx-overlay-shader {
  background-color: rgba(0, 0, 0, 0.41);
  pointer-events: none !important;

  >.dx-overlay-content {
    >.dx-popup-title {
      .dx-toolbar-label {
        max-width: fit-content !important;
      }
    }
  }
}

.dx-dropdowneditor-overlay {
  .dx-popup-normal {
    min-width: 100%;
    width: fit-content !important;

    .dx-list-item {
      width: auto;
      min-width: 100%;
    }
  }
}

.modal-template {
  height: auto !important;
}

.dx-popup-wrapper.dx-overlay-shader {
  // .dx-popup-content {
  //   margin-bottom: -25px;
  // }

  // .dx-toolbar-after {
  //   height: auto !important;
  // }
}

.dx-treeview-select-all-item {
  padding-top: 9px !important;
}

.dx-popup-wrapper:not(.dx-overlay-shader):not(.dx-scheduler-appointment-tooltip-wrapper) {
  .dx-popup-normal {
    min-width: 250px;
    max-width: 750px;
    width: fit-content !important;

    .dx-list-items {
      .dx-list-item {
        width: auto;
        min-width: 100%;
      }
    }
  }

  .dx-popup-content {
    padding: 5px 24px 15px 24px;
  }

  .dx-overlay-content {
    border: none !important;

    .dx-treeview {
      min-width: 100%;
      width: max-content !important;
    }
  }

  .dx-popup-title {
    display: flex;
    align-items: center;
    height: 50px !important;
    padding: 6px 24px;
    border: none;

    .dx-toolbar-label {
      font-size: 22px;
      font-weight: 500;
    }

    .dx-toolbar-items-container {
      height: 30px !important;
    }

    .dx-toolbar-before {
      width: -webkit-fill-available;
    }

    .dx-toolbar-after {
      .dx-toolbar-button {
        height: 33px;

        .dx-closebutton {
          background-color: colors.$transparent !important;

          .dx-button-content {
            padding-inline-start: 8px;
            padding-inline-end: 8px;

            .dx-icon-close {
              color: colors.$black;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.dx-dropdownbox {
  &.dx-editor-filled {
    border: none !important;
    background-color: colors.$primary-500 !important;

    &.dx-state-hover {
      background-color: colors.$primary-600 !important;

      .dx-dropdowneditor-icon {
        background-color: colors.$primary-600 !important;
      }
    }

    &.dx-state-active {
      background-color: colors.$primary-700 !important;

      .dx-dropdowneditor-icon {
        background-color: colors.$primary-700 !important;
      }
    }

    .dx-texteditor-input-container {
      .dx-texteditor-input,
      .dx-placeholder::before {
        color: colors.$white !important;
      }
    }

    .dx-dropdowneditor-icon {
      background-color: colors.$primary-500 !important;

      &::before {
        color: colors.$white;
      }
    }
  }
}

.dx-scheduler-appointment-popup {
  .dx-textarea {
    .dx-texteditor-input-container {
      height: 93px;
    }
  }

  .dx-field-empty-item {
    display: none;
  }
}

.dx-htmleditor {
  border-style: solid !important;

  &.dx-state-readonly {
    border-color: transparent;
  }

  .dx-button-mode-text {
    background-color: colors.$primary-100 !important;

    .dx-icon {
      color: colors.$primary-600;
    }

    &.dx-state-hover {
      background-color: colors.$primary-200 !important;
    }

    &.dx-state-selected {
      background-color: colors.$primary-300 !important;
    }
  }
}

strong {
  font-family: "Inter Bold";
}

.dx-texteditor-with-floating-label {
  &.dx-state-readonly {
    .dx-label {
      border: none !important;
    }
  }
}

.dx-checkbox-text {
  padding-inline-start: 10px;
}

.dx-checkbox-icon {
  width: 16px;
  height: 16px;
}

.dx-checkbox-checked .dx-checkbox-icon::before {
  font-size: 13px;
}

.dx-form {
  .dx-form-group-caption {
    font-size: 14px;
    color: colors.$black;
  }

  .dx-form-group-with-caption {
    .dx-form-group-content {
      border: none !important;
      padding-top: 8px !important;
    }
  }
}

dx-select-box {
  .dx-label-before,
  .dx-label-after,
  .dx-label {
    border: none !important;
  }
}

dx-date-range-box {
  .dx-texteditor.dx-state-focused,
  .dx-texteditor.dx-state-hover {
    border: 1px solid transparent !important;
  }

  .dx-start-datebox,
  .dx-end-datebox {
    border: 1px solid transparent !important;

    &::before {
      display: none;
    }
  }

  .dx-label {
    span {
      font-size: 12px !important;
      top: -21px !important;
    }
  }
}

@font-face {
  font-family: "DXIcons Material";
  src: url(assets/fonts/DXicons-material.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter Regular";
  src: url(assets/fonts/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Inter Light";
  src: url(assets/fonts/Inter-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Inter Bold";
  src: url(assets/fonts/Inter-Bold.ttf) format("truetype");
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-gap-1rem {
  gap: 1rem;
}

.dx-button-mode-text.dx-button-default {
  color: colors.$primary-600 !important;
}

.dx-form-group-with-caption > .dx-form-group-content {
  padding-top: 10px !important;
  border-top: none !important;
}

.dx-form-group-caption {
  font-size: 14px;
}

.dx-item.dx-radiobutton {
  .dx-radiobutton-icon {
    width: 16px;
    height: 16px;
    border: 1px solid colors.$primary-400;

    &.dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
      border-width: 2px;
      background-color: colors.$green;
    }
  }
}

.page-bg-light {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px 20px;
}

.page-title-top-margin {
  margin-top: 25px;
}

.dx-tagbox.dx-texteditor.dx-editor-outlined {
  border-radius: 8px;
  border: none !important;
  padding: 5px 8px;

  .dx-tag-content {
    border-radius: 15px;
    padding: 4px 25px 4px 12px;
    background-color: colors.$primary-500;
    color: #fff;

    .dx-tag-remove-button::after,
    .dx-tag-remove-button::before {
      background: #fff;
    }

    .dx-tag-remove-button::before {
      width: 1px;
      height: 9px;
      left: 10px;
    }

    .dx-tag-remove-button::after {
      width: 9px;
      height: 1px;
      right: 10px;
    }
  }
}

.dx-popup-normal {
  .dx-popup-title {
    border-bottom: none;
    padding: 10px 20px;

    .dx-toolbar-label {
      font-size: 22px;
    }

    .dx-icon-close {
      color: colors.$black;
      font-size: 14px !important;
    }
  }
}

.dx-overlay-shader {
  background-color: rgba(0, 0, 0, 0.5);
}

.dx-treeview-select-all-item {
  padding: 0;
  padding-bottom: 10px;
}

.dx-treeview-with-search {
  .dx-texteditor.dx-editor-outlined {
    border-color: colors.$primary-300;

    &.dx-state-focused {
      border-color: colors.$primary-500;
    }
  }
}

.dx-treeview-item {
  padding: 5px 0px;
}

.dx-treeview-node-container-opened {
  .dx-treeview-node {
    padding-inline-start: 44px;
  }
}

.dx-treeview-toggle-item-visibility {
  width: 8px;
}

.dx-list.striped-list {
  .dx-list-select-all,
  .dx-empty-message {
    border: none !important;
  }

  .dx-list-items {
    border-radius: 8px;
    overflow: hidden;

    .dx-list-item {
      border: none !important;

      &:nth-child(even) {
        background-color: colors.$primary-50;
      }

      &:nth-child(odd) {
        background-color: colors.$primary-100;
      }
    }
  }
}

.dx-treeview-expander-icon-stub {
  display: none !important;
}

.dx-datagrid-table {
  td {
    vertical-align: middle !important;
  }
}

.dx-calendar {
  .dx-calendar-caption-button.dx-button {
    color: colors.$black;
  }

  .dx-button-has-icon {
    .dx-icon {
      color: colors.$black !important;
    }
  }
}

.dx-datebox-datetime-time-side {
  input {
    min-width: 40px !important;
  }
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

.dx-tooltip-date-content {
  text-align: left;

  .dx-tooltip-date {
    font-family: "Inter Bold";
  }
}

.dx-editor-cell {
  .dx-texteditor-input {
    font-size: 12px;
  }
}

.mdc-linear-progress__bar-inner {
  border-color: colors.$primary-400 !important;
}

.mdc-linear-progress__buffer-bar {
  background-color: colors.$primary-200 !important;
}

.disconnect-modal {
  .dx-toolbar-item-content {
    color: colors.$red-error;
  }

  .disconnect-modal-copy {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 18px;
  }

  .disconnect-modal-cta {
    display: flex;
    justify-content: space-between;
  }
}

.dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper {
  .dx-list-item {
    width: 520px !important;
    text-decoration: none;
  }
  
  .dx-tooltip-appointment-item {
    text-decoration: none;
  }
}

.menu-link {
  color: colors.$black !important;
  text-decoration: none;
  width: 100%;
  display: block;
}

.dx-icon-link-tab {
  background-image: url('/assets/img/link-tab.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px !important;
  width: 20px !important;
}
