$primary-50: #f7f8fc;
$primary-100: #eeeff7;
$primary-200: #dae0ff;
$primary-300: #a8aee8;
$primary-400: #767cb6;
$primary-500: #585e98;
$primary-600: #3a407a;
$primary-700: #121852;

$gray-200: #fbfbfb;
$gray-300: #e7e7e7;
$gray-400: #dddddd;
$gray-500: #bfbfbf;
$gray-600: #a1a1a1;
$gray-700: #797979;
$gray-800: #5b5b5b;
$gray-900: #393939;

$transparent: transparent;
$black: #2C2C2C;
$white: #ffffff;
$green: #84ad6d;
$red-error: #ff4343;
$red-error-fill: #ffeeee;

$widget-shadow-color: #585e980a;
$widget-outline-color: #d3d3d30f;
