$base-font-family: Inter Regular;
$base-accent: #585e98;
$base-label-color: #393939;
$base-link-color: #585e98;
$base-icon-color: #393939;
$base-text-color: #393939;
$base-bg: #ffffff;
$base-border-color: #dddddd;
$base-border-radius: 4px;
$base-success: #5cb85c;
$base-warning: #f0ad4e;
$base-danger: #e17073;
$base-hover-color: #393939;
$base-hover-bg: #0000000a;
$base-focus-color: #ffffff;
$base-focus-bg: #585e98;
$base-invalid-color: #e17073;
$base-invalid-faded-border-color: #e1707366;
$base-border-radius-small: 3px;
$base-border-radius-large: 6px;
$button-normal-color: #ffffff;
$button-normal-bg: #585e98;
$button-normal-border-color: transparent;
$button-normal-contained-bg-selected: #464b79;
$button-normal-outlined-bg-selected: #0000002b;
$button-normal-text-bg-selected: #0000002b;
$button-default-color: #262c66;
$button-default-bg: #eeeff7;
$button-default-border-color: transparent;
$button-default-contained-bg-selected: #d6d7de;
$button-default-outlined-bg-selected: #eeeff74d;
$button-default-text-bg-selected: #eeeff74d;
$button-danger-color: #ffffff;
$button-danger-bg: #e17073;
$button-danger-border-color: #dd5b5e;
$button-danger-contained-bg-selected: #cf2b30;
$button-danger-outlined-bg-selected: #e170734d;
$button-danger-text-bg-selected: #e170734d;
$button-success-color: #ffffff;
$button-success-bg: #5cb85c;
$button-success-border-color: #4cae4c;
$button-success-contained-bg-selected: #398439;
$button-success-outlined-bg-selected: #5cb85c4d;
$button-success-text-bg-selected: #5cb85c4d;
$list-border-color: #dddddd;
$list-group-color: #393939;
$list-group-header-bg: #eeeeee0d;
$list-normal-color: #393939;
$list-item-hover-bg: #0000000a;
$list-item-selected-bg: #0000001a;
$list-item-focused-selected-bg: #585e98b3;
$list-focused-bg: #585e98;
$list-item-active-bg: #585e98;
$fieldset-field-label-color: #393939;
$checkbox-border-color: #dddddd;
$checkbox-border-color-focused: #585e98;
$checkbox-bg: #ffffff;
$checkbox-hover-border-color: #424671;
$checkbox-active-icon-bg: #60606033;
$scrollable-scroll-bg: #bfbfbfb3;
$scrollable-scrollbar-active-bg: #bfbfbf33;
$switch-border-color: #dddddd;
$switch-on-color: #393939;
$switch-container-active-bg: #60606033;
$switch-off-color: #9f9f9f;
$tabs-tab-color: #393939;
$tabs-tab-selected-color: #393939;
$tabs-tab-bg: #f7f7f7;
$tabs-tab-selected-bg: #ffffff;
$tabs-border-color: #dddddd;
$tabs-focused-border-color: #585e98;
$tabs-tab-hover-bg: #ffffff;
$badge-color: #ffffff;
$badge-bg: #585e98;
$overlay-content-bg: #ffffff;
$overlay-shader-bg: #ffffffcc;
$overlay-focus-border-color: #dddddd;
$textbox-search-icon-color: #9f9f9f;
$texteditor-color: #393939;
$texteditor-placeholder-color: #9f9f9f;
$texteditor-bg: #ffffff;
$texteditor-filled-bg: #3939390d;
$texteditor-border-color: #dddddd;
$texteditor-focused-border-color: #585e98;
$texteditor-hover-border-color: #585e9866;
$dropdowneditor-button-hover-bg: #454a78;
$dropdowneditor-button-active-bg: #202237;
$dropdowneditor-icon-color: #393939;
$dropdowneditor-icon-active-color: #393939;
$numberbox-spin-active-bg: #202237;
$numberbox-spin-hover-border-color: transparent;
$numberbox-spin-icon-color: #393939;
$calendar-color: #393939;
$calendar-header-color: #393939;
$calendar-cell-other-color: #39393961;
$calendar-bg: #ffffff;
$calendar-hover-bg: #585e983d;
$calendar-cell-selected-bg: #585e98;
$calendar-cell-active-bg: #585e9866;
$loadindicator-bg: #666666;
$treeview-focused-bg: #585e98;
$treeview-hover-bg: #0000000a;
$treeview-border-color: #dddddd;
$treeview-item-selected-color: #393939;
$treeview-spin-icon-color: #393939;
$menu-popup-bg: #ffffff;
$menu-item-hover-bg: #0000000a;
$menu-color: #393939;
$menu-item-hovered-color: #393939;
$menu-item-expanded-color: #393939;
$menu-item-selected-bg: #e6e6e6;
$menu-popup-border-color: #dddddd;
$selectbox-list-bg: #ffffff;
$toolbar-bg: #ffffff;
$popup-title-bg: transparent;
$tileview-color: #393939;
$toast-color: #ffffff;
$toast-info-bg: #585e98;
$toast-warning-bg: #f0ad4e;
$toast-error-bg: #e17073;
$toast-success-bg: #5cb85c;
$progressbar-bg: #dddddd;
$progressbar-range-bg: #585e98;
$tooltip-color: #393939;
$tooltip-bg: #ffffff;
$tooltip-border-color: #dddddd;
$slider-bar-bg: #dddddd;
$slider-range-bg: #585e98;
$gallery-indicator-bg: #ffffff;
$gallery-indicator-item-selected-bg: #585e98;
$gallery-indicator-focused-bg: #3c4068;
$gallery-navbutton-hover-color: #585e9880;
$gallery-nav-arrow-color: #ffffff;
$gallery-navbutton-active-color: #585e98b3;
$loadpanel-content-bg: #ffffff;
$tagbox-tag-color: #393939;
$tagbox-tag-bg: #dddddd;
$tagbox-tag-button-remove-bg: #aaaaaa;
$radiogroup-checked-bg: #585e98;
$radiobutton-active-bg: #60606033;
$accordion-color: #393939;
$accordion-title-color: #393939;
$accordion-item-title-opened-bg: transparent;
$accordion-item-border-color: #dddddd;
$accordion-item-focused-border-color: #585e98;
$accordion-title-active-color: #393939;
$accordion-item-hover-bg: #0000000a;
$colorbox-overlay-bg: #ffffff;
$datagrid-base-color: #393939;
$datagrid-base-background-color: #ffffff;
$datagrid-border-color: #dddddd;
$datagrid-columnchooser-item-color: #7b7b7b;
$datagrid-columnchooser-font-weight: normal;
$datagrid-drag-header-border-color: #585e9880;
$datagrid-selection-bg: #e6e6e6;
$datagrid-row-selected-border-color: #dddddd;
$datagrid-row-selected-color: #393939;
$datagrid-row-focused-color: #393939;
$datagrid-row-focused-bg: #dedfea;
$datagrid-hover-bg: #0000000a;
$datagrid-menu-icon-color: #8f8f8f;
$datagrid-cell-modified-border-color: #5cb85c80;
$datagrid-row-invalid-faded-border-color: #e1707366;
$datagrid-nodata-color: #393939;
$datagrid-group-row-color: #282828;
$datagrid-group-row-bg: #f7f7f7;
$datagrid-search-color: #ffffff;
$datagrid-spin-icon-color: #9b9b9b;
$datagrid-search-bg: #585e98;
$datagrid-row-error-color: #ffffff;
$datagrid-row-error-bg: #eda9ab;
$datagrid-link-color: #585e98;
$datagrid-focused-border-color: #797ead;
$datagrid-editor-bg: #ffffff;
$datagrid-columnchooser-bg: #ffffff;
$datagrid-row-alternation-bg: #f5f5f5;
$datagrid-summary-color: #393939b3;
$pager-page-selected-color: #393939;
$pager-page-selected-bg: #d4d4d4;
$pivotgrid-area-color: #9b9b9b;
$pivotgrid-totalcolor: #dddddd33;
$pivotgrid-grandtotalcolor: #f5f5f5;
$pivotgrid-field-area-text-color: #585858;
$pivotgrid-spin-icon-color: #9b9b9b;
$fileuploader-filename-color: #393939;
$fileuploader-falename-status-color: #9f9f9f;
$fileuploader-border-color: #dddddd;
$scheduler-base-border-color: #dddddd99;
$scheduler-accent-border-color: #c4c4c4;
$scheduler-appointment-text-color: #ffffff;
$scheduler-appointment-base-color: #585e98;
$scheduler-appointment-start-color: #0000004d;
$scheduler-first-month-border-color: #aaaaaa;
$scheduler-workspace-focused-cell-color: #e5e6f0;
$scheduler-current-time-cell-color: #585e98;
$scheduler-time-indicator-color: #8489b8;
$form-field-item-color: #3a407a;
$form-group-border-color: #dddddd;
$filterbuilder-text-color: #393939;
$filterbuilder-text-focus-color: #ffffff;
$filterbuilder-plus-icon-color: #5cb85c4d;
$filterbuilder-remove-icon-color: #e170734d;
$filterbuilder-group-operation-color: #e170734d;
$filterbuilder-item-field-color: #585e984d;
$filterbuilder-item-operator-color: #5cb85c4d;
$filterbuilder-item-value-color: #dddddd80;
$filterbuilder-menu-icon-color: #393939;
$drawer-shader-background-color: #00000080;
